import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { IoIosArrowForward, IoIosCheckmark, IoMdOpen } from "react-icons/io";
import {
  IoHeadset,
  IoRibbon,
  IoTrendingUp,
  IoPeople,
  IoShieldCheckmark,
  IoAnalytics,
} from "react-icons/io5";
import { useModal } from "../components/useModal";
import { IframeContainer } from "../components/site";

import {
  Button,
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
} from "../components/util";
import {
  CustomerQuote,
  ImageGraphic,
  Modal,
  PartnerLevels,
} from "../components/site";
import { PartnerInterestForm } from "../components/forms/";
import Layout from "../components/layout";
import Image from "../components/image";
import Seo from "../components/seo";

import PayHeroLogo from "../images/logos/PayHero/PayHero_Full.svg";
import DroppahLogo from "../images/logos/Droppah/Droppah_Full_Black.svg";
import InvoxyLogo from "../images/logos/Invoxy/Invoxy_Full.svg";
import PayHeroLogo_Single from "../images/logos/PayHero/PayHero_Single.svg";
import DroppahLogo_Single from "../images/logos/Droppah/Droppah_Single.svg";
import InvoxyLogo_Single from "../images/logos/Invoxy/Invoxy_Single.svg";

const TextCallout = styled.div`
  .gatsby-image-wrapper {
    margin-left: auto;
  }
`;

const PayHeroFeatures = styled(Box)`
  svg {
    font-size: 30px;
    min-width: 30px;
    margin-right: 5px;
    color: ${(props) => props.theme.colours.green};
  }
`;

const CustomerName = styled.h4`
  color: #fff;
  position: absolute;
  bottom: 60px;
  left: 60px;
`;

const FlexiTimeProducts = styled(Row)`
  align-items: stretch;

  > div {
    padding: 30px;
    text-align: center;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: ${(props) => props.theme.styles.borderRadius};

    img {
      margin-bottom: 25px;
      margin-top: 25px;
      max-width: 200px;
      width: 90%;
      max-height: 30px;
    }

    h2 {
      margin: 10px 0;
    }
  }
`;

const Home = (props) => {
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  return (
    <Layout>
      <Seo
        title="Become a Partner | FlexiTime"
        description="Provide businesses with your outstanding client service and FlexiTime's powerful suite of workforce management solutions. Register interest today."
        pathname={props.location.pathname}
      />
      <Container className="-textCenter">
        <Wrapper>
          <Box stackGap={40} centerOnMobile>
            <Box stackGap={30} className="-center" css={{ maxWidth: "1000px" }}>
              <Row justify="center" stackGap={30} alignCenter noBreak>
                <img src={PayHeroLogo_Single} height="40" />
                <img src={DroppahLogo_Single} height="38" />
                <img src={InvoxyLogo_Single} height="40" />
              </Row>
              <Box stackGap={10}>
                <h1 className="h1 -fontLarge">
                  Grow your business by partnering with FlexiTime
                </h1>
                <h4 className="-fontNormal">
                  Provide businesses with your outstanding client service and
                  FlexiTime's powerful suite of workforce management solutions.
                </h4>
              </Box>
            </Box>
            <FlexButtons justify="center">
              <Button className="primary -lg" to="#register" atag>
                Become a Partner
              </Button>
              <Button className="grey -lg gtm-cta" onClick={toggleModal} atag>
                Book a Call
              </Button>
            </FlexButtons>
            <div className="-center -addShadow" css={{ maxWidth: "1000px", marginTop: '100px' }}>
              <IframeContainer
                src="https://player.vimeo.com/video/996430475"
                title="Inside the PayHero Partner Program & Product Benefits | Partner Testimonials"
                noBottomRadius
              />
            </div>

          </Box>
        </Wrapper>
      </Container>
      <Container bg="Tertiary" className="-whiteOut -textCenter">
        <Wrapper>
          <h2 className="h1">Why become a FlexiTime partner?</h2>
          <Box stackGap={40}>
            <Row stackGap={40}>
              <Box size={33}>
                <h3>
                  <IoPeople />
                  <br />
                  Partner management app
                </h3>
                <p>
                  Manage your client accounts and partner staff access levels
                  from your partner app.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoRibbon />
                  <br />
                  Partner rewards
                </h3>
                <p>
                  Partners receive exclusive pricing benefits, providing
                  flexibility in how you and your clients manage subscriptions.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoTrendingUp />
                  <br />
                  Grow your practice
                </h3>
                <p>
                  Increase your revenue per client and build better
                  relationships without the hassle or added complexity that
                  often comes with payroll.
                </p>
              </Box>
            </Row>
            <Row stackGap={40}>
              <Box size={33}>
                <h3>
                  <IoShieldCheckmark />
                  <br />
                  Focus on compliance
                </h3>
                <p>
                  Our payroll software has been built to follow MBIE’s 2017
                  Holidays Act guidance, giving you and your clients peace of
                  mind.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoAnalytics />
                  <br />
                  Advanced reporting
                </h3>
                <p>
                  Leverage our powerful and customisable reporting tools to
                  crunch the data you need to advise your clients on how to grow
                  their business.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoHeadset />
                  <br />
                  Priority support & training
                </h3>
                <p>
                  Partners receive free training, priority phone and email
                  support and access to a dedicated account manager.
                </p>
              </Box>
            </Row>
          </Box>
          {/* <CustomerQuoteLarge
            quote="We’re usually reluctant to endorse payroll software, simply because the cost of getting it wrong is huge. PayHero gets the detail right, at the same time as being exceptionally easy to use. In short, it’s second to none."
            name="Hamish Mexted"
            company="Convex Accounting"
            hero="HamishAndRiannFromConvex.jpg"
            pic="Hamish_Circle.png"
            logo="ConvexAccountingLogo.png"
            link="https://convexaccounting.co.nz/knowledge-library/xero-addon-payhero"
          /> */}
          <CustomerQuote
            quote="We’re usually reluctant to endorse payroll software, simply because the cost of getting it wrong is huge. PayHero gets the detail right, at the same time as being exceptionally easy to use. In short, it’s second to none."
            name="Hamish Mexted"
            company="Convex Accounting"
            pic="Hamish_Circle.png"
            center
            large
          />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper noPaddingBottom>
          <Row stackGap={80}>
            <Box size={40} stackGap={50} centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h1">
                  <span className="-fontPrimary">
                    All the tools your clients need
                  </span>{" "}
                  to pay their employees right
                </h2>
                <h4 className="-fontNormal">
                  Unburden your clients and their staff from the complexity of
                  paperwork and compliance and let them focus on doing their
                  best work.
                </h4>
              </Box>
              <CustomerQuote
                quote="Client feedback has been overwhelmingly positive, especially around time savings, simplifying public holiday payroll calculations, and generally reducing stress around payroll."
                name="Scotty Jenkin"
                company="Noone Plus"
                pic="PartnerCircle_Scotty.png"
              />
            </Box>
            <Box size={60} stackGap={70}>
              <PayHeroFeatures stackGap={20}>
                <Row stackGap={20}>
                  <Box isFlex size={50}>
                    <IoIosCheckmark /> 24/7 online access from anywhere
                  </Box>
                  <Box isFlex size={50}>
                    <IoIosCheckmark /> No software to install
                  </Box>
                </Row>
                <Row stackGap={20}>
                  <Box isFlex size={50}>
                    <IoIosCheckmark /> Free upgrades & backups
                  </Box>
                  <Box isFlex size={50}>
                    <IoIosCheckmark /> Leave balances stored in weeks
                  </Box>
                </Row>
                <Row stackGap={20}>
                  <Box isFlex size={50}>
                    <IoIosCheckmark /> Expert support
                  </Box>
                  <Box isFlex size={50}>
                    <IoIosCheckmark /> Automated Payday Filing
                  </Box>
                </Row>
                <Row stackGap={20}>
                  <Box isFlex size={50}>
                    <IoIosCheckmark /> Online employee timesheets
                  </Box>
                  <Box isFlex size={50}>
                    <IoIosCheckmark /> Photo time clock
                  </Box>
                </Row>
                <Row stackGap={20}>
                  <Box isFlex size={50}>
                    <IoIosCheckmark /> Automatic public holiday calculations
                  </Box>
                  <Box isFlex size={50}>
                    <IoIosCheckmark /> Online leave requests & approvals
                  </Box>
                </Row>
                <Row stackGap={20}>
                  <Box isFlex size={50}>
                    <IoIosCheckmark /> Business intelligence reporting
                  </Box>
                  <Box isFlex size={50}>
                    <IoIosCheckmark /> Client management dashboard
                  </Box>
                </Row>
              </PayHeroFeatures>
            </Box>
          </Row>
          <Image
            filename="FlexiTime_Suite_Full_Cropped.png"
            alt="Online Payroll & Workforce Management Solutions for Flexible Employees"
            addShadow
            centerImage
            maxWidth={1100}
          />
        </Wrapper>
      </Container>
      <Container bg="Tertiary">
        <Wrapper>
          <Row stackGap={60}>
            <Box size={40} stackGap={50} className="-whiteOut" centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h1">
                  <span className="-fontPrimary">Help your clients grow</span>{" "}
                  with software that supports their team
                </h2>
                <h4 className="-fontNormal">
                  FlexiTime builds software that simplifies employment. We offer
                  a range of integrated solutions to help businesses manage and
                  pay their employees.
                </h4>
              </Box>
              <TextCallout className="hideOnMobile">
                <Image
                  filename="OtherProducts_Text.png"
                  alt="FlexiTime Products"
                  maxWidth={250}
                />
              </TextCallout>
            </Box>
            <Box size={60}>
              <FlexiTimeProducts stackGap={20}>
                <Box size={33} stackGap={40}>
                  <div>
                    <span className="-fontBold">Payroll</span>
                    <img
                      className="-center"
                      src={PayHeroLogo}
                      alt="PayHero | Online Payroll Software"
                    />
                    <h4>
                      Pay your employees right with smart, modern and seriously
                      compliant online payroll software.
                    </h4>
                  </div>
                  <Box stackGap={30}>
                    <a
                      href="https://www.payhero.co.nz"
                      target="_blank"
                      rel="noreferrer"
                      className="link-arrow -center"
                    >
                      Discover PayHero <IoMdOpen />
                    </a>
                  </Box>
                  {/* <div className="banner -lightGrey">Coming Soon</div> */}
                </Box>
                <Box size={33} stackGap={40}>
                  <div>
                    <span className="-fontBold">Scheduling</span>
                    <img
                      className="-center"
                      src={DroppahLogo}
                      alt="Droppah | Scheduling, timesheet & attendance software"
                    />
                    <h4>
                      Roster your staff and track time and attendance to control
                      costs & optimise staff coverage.
                    </h4>
                  </div>
                  <Box stackGap={30}>
                    <a
                      href="https://www.droppah.com"
                      target="_blank"
                      rel="noreferrer"
                      className="link-arrow -center"
                    >
                      Discover Droppah <IoMdOpen />
                    </a>
                  </Box>
                  {/* <div className="banner -lightGrey">Coming Soon</div> */}
                </Box>
                <Box size={33} stackGap={40}>
                  <div>
                    <span className="-fontBold">Contractor Management</span>
                    <img
                      className="-center"
                      src={InvoxyLogo}
                      alt="Karmly | Contractor Management software"
                    />
                    <h4>
                      Increase billing accuracy and get paid faster with
                      timesheets, approvals & batch invoicing.
                    </h4>
                  </div>
                  <Box stackGap={30}>
                    <a
                      href="https://www.karmly.com/recruitment"
                      target="_blank"
                      rel="noreferrer"
                      className="link-arrow -center"
                    >
                      Discover Karmly <IoMdOpen />
                    </a>
                  </Box>
                </Box>
              </FlexiTimeProducts>
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <Box className="-textCenter -center">
            <h2 className="h1">
              The more you grow,{" "}
              <span className="-fontPrimary">the more you get</span>
            </h2>
            <h4 className="-fontNormal">
              As a FlexiTime partner, you’ll get access to benefits, resources
              and tools to help your clients and reach new ones.
            </h4>
          </Box>
          <Box stackGap={50}>
            <PartnerLevels />
            <p css={{ color: "#999", textAlign: "center" }}>
              <a href="#register">Register interest</a> to find out about
              partner requirements, discounts and more.
            </p>
          </Box>
          <CustomerQuote
            quote="PayHero has been amazing. The documents and notes section has proved to be very valuable as now all HR docs are stored with the payroll information. It's really simple to use and looks great."
            name="Haylee Wrenn"
            company="Accountabill"
            pic="PartnerCircle_Haylee.png"
            center
            large
          />
        </Wrapper>
      </Container>
      <Container id="register" bg="Tertiary" className="-whiteOut">
        <Wrapper>
          <Box stackGap={70}>
            <Box stackGap={5} className="-textCenter">
              <h4>Partner with FlexiTime</h4>
              <h2 css={{ color: "#fff" }} className="h1">
                <span className="-fontPrimary">Get all the tools you need</span>{" "}
                to manage <br className="hideOnMobile" /> your business and your
                clients.
              </h2>
            </Box>
            <Box className="-center" css={{ maxWidth: "700px" }}>
              <PartnerInterestForm />
            </Box>
          </Box>
        </Wrapper>
      </Container>
      <Modal
        isActive={itemModalOpen}
        embedURL="https://calendly.com/flexitime-sales/sales-consultation"
        embedTitle="Book a Sales Call | FlexiTime Sales"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default Home;
